import { FC } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { MenuItem } from '../../../store/slices/menuSlice';
import styles from './sider.module.css';
import { useAppDispatch } from '../../../store/store';
import { setActiveItem } from '../../../store/slices/menuSlice';

interface SiderItemMenu {
    item: MenuItem;
    activeItem: string;
}

const SiderItemMenu: FC<SiderItemMenu> = (props) => {
    const { item, activeItem } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const setActive = (itemKey: string) => {
        dispatch(setActiveItem(itemKey));
    };

    return (
        <Link
            to={item.path}
            style={{ backgroundColor: item.key === activeItem ? '#38649f' : '' }}
            className={styles.item}
            onClick={() => {
                navigate(item.path);
                setActive(item.key);
            }}
        >
            {/* {itemNav.icon} */}
            <div className={styles.itemTitle}>{item.label}</div>
        </Link>
    );
};

export default SiderItemMenu;
