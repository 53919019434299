import WelcomeImg from '../assets/logo-black.svg'
import {Button} from "antd";
import {useNavigate} from 'react-router-dom'
const WelcomePage = () =>{
    const navigate = useNavigate()
    return(
        <div className='welcome-container'>
{/*            <img
                src={WelcomeImg}
                alt="WelcomeImg"
                width={300}
                height={300}
            />*/}
          <WelcomeImg />
            <p className='welcome-text'>Добро пожаловать в HR APP!</p>
            <Button type='primary' onClick={() => navigate('/vacancies')}>
                Начать использование
            </Button>
        </div>
    )
}

export default WelcomePage
