import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

export interface MenuItem {
    label: string;
    key: string;
    path: string;
    icon?: ReactNode;
}

interface InitialState {
    menuItems: MenuItem[];
    activeItem: string;
}

const initialState: InitialState = {
    menuItems: [
        {
            label: 'Вакансии',
            key: 'vacancies',
            path: '/vacancies',
        },
        {
            label: 'База',
            key: 'candidates',
            path: '/candidates',
        },
        {
            label: 'Календарь',
            key: 'calendar',
            path: '/calendar',
        },
        {
            label: 'Настройки',
            path: '/settings',
            key: 'settings',
        },
    ],
    activeItem: 'main',
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setActiveItem: (state, action: PayloadAction<string>) => {
            state.activeItem = action.payload;
        },
    },
});

export default menuSlice.reducer;

export const { setActiveItem } = menuSlice.actions;
