import { Outlet } from 'react-router-dom';
import { Header, Sider} from '../index';
import styles from './layout.module.css';

const Layout = () => (
    <>
        <Header />
        <main className={styles.main}>
            <Sider />
            <div className={styles.content}>
                <Outlet />
            </div>
        </main>
    </>
);

export default Layout;
