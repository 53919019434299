import React, {useState, useEffect,} from "react";
import { CandidatesApiFactory, CandidateDtoResponse } from '../generated/backend'
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
const CandidatesPage: React.FC = () =>{
    const [candidates, setCandidates] = useState<CandidateDtoResponse[]>([])
    useEffect(() => {
        const fetchCandidates = async () =>{
            try {
                const response = await CandidatesApiFactory().apiCandidateGet()
                setCandidates(response.data)
            } catch (error){
                console.error('Ошибка при загрузке вакансий', error)
            }
        }
        fetchCandidates()
    }, []);
    const columns: ColumnsType<CandidateDtoResponse> = [
        {
            key: 'name',
            title: 'Кандидаты',
            dataIndex: 'name',
        },
        {
            key: 'notes',
            title: 'Заметки',
            dataIndex: 'notes',
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
        },
        {
            key: 'phoneNumber',
            title: 'Номер телефона',
            dataIndex: 'phoneNumber',
        },
        {
            key: 'telegram',
            title: 'Telegram',
            dataIndex: 'telegram',
        },
        {
            key: 'citizenship',
            title: 'Гражданство',
            dataIndex: 'citizenship',
        },
    ]
    return(
        <div>
            <h2 style={{marginBottom: '16px'}}>База кандидатов</h2>
            <Table<CandidateDtoResponse>
                columns={columns}
                dataSource={candidates}
                pagination={{pageSize: 7}}
                rowKey='id'
                rowSelection={{}}
            />
        </div>
    )
}
export default CandidatesPage