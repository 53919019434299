import styles from './header.module.css';
import Logo from '../../../assets/logo-white-min.svg';

import { Breadcrumbs } from '../index';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className={styles.header}>
      <div className={styles.logo} onClick={() => navigate('/')}>
        <Logo/>
        <div className={styles.logoTitle}>HR</div>
      </div>
      <div className={styles.content}>
        <ul className={styles.breadCrumbs}>
          <Breadcrumbs/>
        </ul>
      </div>
    </header>
  );
};

export default Header;
