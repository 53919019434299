import { useAppSelector } from '../../../store/store';
import styles from './sider.module.css';
import SiderItemMenu from './SiderItemMenu';

const Sider = () => {
    const { menuItems, activeItem } = useAppSelector((state) => state.menu);

    return (
        <div className={styles.sider}>
            {menuItems.map((item) => (
                <SiderItemMenu item={item} activeItem={activeItem} key={item.path} />
            ))}
        </div>
    );
};

export default Sider;
